import { Link } from "react-router-dom";

import RecordDisplay from "./RecordDisplay"

// TODO refactor recordsPreview, maybe loggedUser is no longer needed

function recordsPreview({ records, loggedUser }: { records: any[], loggedUser: boolean }) {
    return (
        <div className="flex flex-wrap justify-center items-center">
            {records.map((record: any) => {
                let lowestPrice = record.stock.stockItems.reduce((min: number, stock: any) => Math.min(min, stock.price), Number.MAX_SAFE_INTEGER);
                lowestPrice = lowestPrice < 0 ? 0 : lowestPrice;
                let highestPrice = record.stock.stockItems.reduce((max: number, stock: any) => Math.max(max, stock.price), Number.MIN_SAFE_INTEGER);
                const recordsInStock = record.stock.stockItems.length;
                return (
                    <article
                        key={record._id}
                        // TODO implement resizing
                        className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/2 py-2 px-2 pl-0 mb-4"
                    >
                        <Link to={`/records/${record._id}`}>
                            <RecordDisplay record={record} coverSize="mq" />
                        </Link>
                        <div className="text--light-cyan">
                            <p><b>{recordsInStock}</b> available record{recordsInStock > 1 ? 's' : null}: </p>
                            <p>Price{lowestPrice !== highestPrice ? (` range: ${lowestPrice}–${highestPrice}€`) : (`: ${lowestPrice}`)}</p>
                        </div>
                        <div className="mt-2">
                        </div>
                        {/* TODO better record styles button */}
                    </article>
                )
            }
            )
            }
        </div>
    )
}

export default recordsPreview