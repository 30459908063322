import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { min: number; max: number } = {
  min: 0,
  max: Infinity,
};

export const priceRangeSlice = createSlice({
  name: "pricerange",
  initialState,
  reducers: {
    applyPriceRange: (state, action: PayloadAction<any>) => {
      state.min = action.payload.min;
      state.max = action.payload.max;
    },
  },
});

export const { applyPriceRange } = priceRangeSlice.actions;

export default priceRangeSlice.reducer;
