import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Condition {
  _id: string;
  condition: string;
}

interface StockItem {
  _id: string;
  condition: Condition;
  price: number;
  store: string;
}

interface Record {
  _id: string;
  genre: string;
  title: string;
  artist: string;
  description: string;
  year: number;
  stock: string;
}

interface RecordInStock {
  record: Record;
  stockItems: StockItem[];
}

interface StoreDetails {
  _id: string;
  name: string;
  location: string;
  website: string;
  rating: number;
  description: string;
  contactEmail: string;
  phoneNumber: string;
  openingHours: string;
  returnPolicy: string;
  featuredItems: string[];
  shippingInfo: string;
  recordsInStock: RecordInStock[];
}

interface StoreState {
  storeDetails: StoreDetails | null;
}

const initialState: StoreState = {
  storeDetails: null,
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setStoreDetails: (state, action: PayloadAction<StoreDetails>) => {
      state.storeDetails = action.payload;
    },
    updateRecordInStock: (
      state,
      action: PayloadAction<{ recordId: string; record: Record }>
    ) => {
      const { recordId, record } = action.payload;
      const index = state.storeDetails?.recordsInStock.findIndex(
        (r) => r.record._id === recordId
      );
      if (index !== undefined && index >= 0) {
        state.storeDetails!.recordsInStock[index].record = record;
      }
    },
    updateStockItemInStock: (
      state,
      action: PayloadAction<{
        recordId: string;
        stockItemId: string;
        stockItem: StockItem;
      }>
    ) => {
      const { recordId, stockItemId, stockItem } = action.payload;
      const recordIndex = state.storeDetails?.recordsInStock.findIndex(
        (r) => r.record._id === recordId
      );
      if (recordIndex !== undefined && recordIndex >= 0) {
        const stockIndex = state.storeDetails!.recordsInStock[
          recordIndex
        ].stockItems.findIndex((s) => s._id === stockItemId);
        if (stockIndex !== -1) {
          state.storeDetails!.recordsInStock[recordIndex].stockItems[
            stockIndex
          ] = stockItem;
        }
      }
    },
    addStockItemToRecord: (
      state,
      action: PayloadAction<{
        recordId: string;
        newStockItem: StockItem;
      }>
    ) => {
      const { recordId, newStockItem } = action.payload;
      const recordIndex = state.storeDetails?.recordsInStock.findIndex(
        (r) => r.record._id === recordId
      );
      if (recordIndex !== undefined && recordIndex >= 0) {
        state.storeDetails!.recordsInStock[recordIndex].stockItems.push(
          newStockItem
        );
      } else {
        console.warn(`Record with ID not found.`);
      }
    },
    deleteStockItemFromRecord: (
      state,
      action: PayloadAction<{
        recordId: string;
        stockItemId: string;
      }>
    ) => {
      const { recordId, stockItemId } = action.payload;
      const recordIndex = state.storeDetails?.recordsInStock.findIndex(
        (r) => r.record._id === recordId
      );
      if (recordIndex !== undefined && recordIndex >= 0) {
        const stockItems =
          state.storeDetails?.recordsInStock[recordIndex].stockItems;
        const stockItemIndex = stockItems!.findIndex(
          (item) => item._id === stockItemId
        );
        if (stockItemIndex !== -1) {
          stockItems!.splice(stockItemIndex, 1);
        }
      }
    },
  },
});

export const {
  setStoreDetails,
  updateRecordInStock,
  updateStockItemInStock,
  addStockItemToRecord,
  deleteStockItemFromRecord,
} = storeSlice.actions;

export default storeSlice.reducer;
