import { HeaderTwoProps } from '../../types/Types'

function HeaderTwo(props: HeaderTwoProps) {
    const { title, position } = props;
    return (
        <h2 className={`mt-4 mb-4 text-3xl text--light-cyan bebas-neue-regular ${position}`}>{title}</h2>
    )
}

export default HeaderTwo
