import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { RecordApiResponse } from "../types/Record";
import { buildUrlWithQueryParams } from "../utils/recordsUtils";
import { apiUrl } from "../utils/utils";

const baseUrl = apiUrl + process.env.REACT_APP_GR_RECORDS_PATH;

export const recordsApi = createApi({
  reducerPath: "recordsApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    fetchRecords: builder.query<
      RecordApiResponse,
      {
        min?: number;
        max?: number;
        genres?: string[];
        page: number;
        sortPrice: string;
      }
    >({
      query: ({ min, max, genres, page, sortPrice }) =>
        buildUrlWithQueryParams(baseUrl, { min, max, genres, page, sortPrice }),
    }),
    // TODO type
    fetchRecordById: builder.query<any, string>({
      query: (recordId) => `/${recordId}`,
    }),
    fetchCategories: builder.query<any[], void>({
      query: () => "/genres",
    }),
    fetchConditions: builder.query<any[], void>({
      query: () => "/conditions",
    }),
    createRecordAndStockItem: builder.mutation({
      query: ({
        jwToken,
        title,
        artist,
        genre,
        year,
        description,
        condition,
        price,
        store,
      }) => ({
        url: "/",
        method: "POST",
        body: {
          title,
          artist,
          genre,
          year,
          description,
          condition,
          price,
          store,
        },
        headers: {
          Authorization: `Bearer ${jwToken}`,
        },
      }),
    }),
  }),
});

export const {
  useFetchRecordsQuery,
  useFetchRecordByIdQuery,
  useFetchCategoriesQuery,
  useFetchConditionsQuery,
  useCreateRecordAndStockItemMutation,
} = recordsApi;
