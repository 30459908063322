import React from 'react';
import PageHeader from '../components/headers/PageHeader';
import NotificationBox from '../components/shared/NotificationBox';
import { useLoggedInState } from '../utils/useLoggedInState';

const Home: React.FC = () => {
  const loggedUser = useLoggedInState()

  return (
    <div className="p-4 w-full lg:max-w-6xl mx-auto">
      <PageHeader
        title="Welcome to Golden Rack"
        subtitle="Discover the Soundtrack of Your Life"
      />
      {!loggedUser && (
        <NotificationBox
          message="Join Golden Rack today."
          to="/register"
          toMessage="Sign up now to explore our collection!"
        />
      )}
    </div>
  );
};

export default Home;