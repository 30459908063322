import { PageHeaderProps } from "../../types/Types";

function PageHeader(props: PageHeaderProps) {
    const { title, subtitle } = props;

    return (
        <div className="text-center bebas-neue-regular">
            <h1 className="text-7xl text--light-cyan">{title}</h1>
            <h2
                className="text-4xl mt-6 text--midnight-green"
                dangerouslySetInnerHTML={{ __html: subtitle }}
            ></h2>
        </div>
    );
}

export default PageHeader;
