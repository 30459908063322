import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { current: number; total: number } = {
  current: 1,
  total: 1,
};

export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    updateCurrentPage: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    updateTotalPages: (state, action: PayloadAction<any>) => {
      state.total = action.payload;
    },
  },
});

export const { updateCurrentPage, updateTotalPages } = pageSlice.actions;

export default pageSlice.reducer;
