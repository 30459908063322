import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Order {
  _id: string;
  record: {
    _id: string;
    title: string;
    artist: string;
  };
  stockItem: {
    _id: string;
    price: number;
  };
  store: {
    _id: string;
    name: string;
  };
}

interface CartState {
  orders: Order[];
}

const initialState: CartState = {
  orders: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addOrder: (state, action: PayloadAction<Order>) => {
      state.orders.push(action.payload);
    },
    removeOrder: (state, action: PayloadAction<string>) => {
      state.orders = state.orders.filter(
        (order) => order._id !== action.payload
      );
    },
    cleanCart: (state) => {
      state.orders = [];
    },
  },
});

export const { addOrder, removeOrder, cleanCart } = cartSlice.actions;

export default cartSlice.reducer;
