import { Link } from 'react-router-dom';

const Logo = () => {
    return (
        <Link to="/">
            <div className="flex items-center mr-3">
                <span className="bungee-spice-regular">Golden Rack</span>
            </div>
        </Link>
    );
};

export default Logo;
