import CategoryButton from './CategoryButton';
import DataLoader from "../../utils/DataLoader";
import HeaderTwo from "../headers/HeaderTwo";
import { useFetchCategoriesQuery } from '../../store/recordsApi';

// TODO genres type
// TODO genres section props

function CategoriesSection({ selectedCategories, handleCategorySelect }: any) {
  const { data: categories = [], isLoading: categoriesLoading, error: categoriesError } = useFetchCategoriesQuery();

  return (
    <DataLoader
      isLoading={categoriesLoading}
      error={categoriesError}
      data={categories}
      emptyMessage="There are no categories to be displayed"
      render={() => (
        <section>
          <HeaderTwo title="Filter by genres:" />
          {categories.map((category: any, i) => (
            <CategoryButton
              key={`${category.name}-${i}`}
              category={category.name}
              selected={selectedCategories.includes(category.name)}
              onClick={() => handleCategorySelect(category.name)}
              size="large"
            />
          ))}
        </section>
      )}
    />
  );
}

export default CategoriesSection
