import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface StockItem {
  condition: string;
  store: string;
  price: number;
  _id: string;
}

const initialState: { createdStockItem: StockItem | null } = {
  createdStockItem: null,
};

const stockItemResponseSlice = createSlice({
  name: "stockItemResponse",
  initialState,
  reducers: {
    setCreateStockItem(state, action: PayloadAction<StockItem>) {
      state.createdStockItem = action.payload;
    },
    clearCreateStockItem(state) {
      state.createdStockItem = null;
    },
  },
});

export const { setCreateStockItem, clearCreateStockItem } =
  stockItemResponseSlice.actions;
export default stockItemResponseSlice.reducer;
