import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetConditionsQuery } from '../../store/conditionsApi';
import DataLoader from '../../utils/DataLoader';
import { clearSelectedConditionId, setSelectedCondition } from '../../store/conditionSlice';
import { useDeleteStockItemMutation, useUpdateStockItemMutation } from '../../store/storesApi';
import { addStockItemToRecord, deleteStockItemFromRecord, updateStockItemInStock } from '../../store/storeSlice';
import { clearNewPrice, setNewPrice } from '../../store/priceSlice';
import CreateStockItemForm from './CreateStockItemForm';
import { getUserFromLocalStorage } from '../../utils/utils';
import { RootState } from '../../types/Types';
import { clearCreateStockItem } from '../../store/stockItemResponseSlice';
import { smallButtonStyleLight, bigButtonStyleLight } from '../../utils/utils';

type UpdateStockItemPayload = {
    stockItemId: string;
    conditionId?: string;
    price?: number;
};

function StockDisplay({ stock, recordId }: { stock: any[], recordId: string }) {
    const user = getUserFromLocalStorage();
    const dispatch = useDispatch();
    const selectedCondition = useSelector((state: any) => state.condition.selectedCondition);
    // TODO fix for createStockItem notification and not createLoveToken
    const createStockItemNotification = useSelector((state: RootState) => state.notification.createLoveToken);
    const stockItemResponseData = useSelector((state: any) => state.stockItemResponse.createdStockItem);
    const newPrice = useSelector((state: any) => state.price.newPrice);
    const { data: response, isLoading, error } = useGetConditionsQuery({});
    const [updateStockItem] = useUpdateStockItemMutation();
    const [deleteStockItem] = useDeleteStockItemMutation();
    const [editMode, setEditMode] = useState<string | null>(null);
    const [showCreateStockItemForm, setShowCreateStockItemForm] = useState<boolean>(false);

    const toggleEditMode = (id: string | null) => {
        setEditMode(id);
        if (id === null) {
            dispatch(clearSelectedConditionId({}));
            dispatch(clearNewPrice({}));
        }
    };

    const handleConditionChange = (e: React.ChangeEvent<HTMLSelectElement>, itemId: string) => {
        if (editMode === itemId) {
            dispatch(setSelectedCondition(e.target.value));
        }
    }

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
        if (editMode === itemId) {
            dispatch(setNewPrice(e.target.value));
        }
    }

    const handleDelete = async ({ stockItemId, storeId, recordId }: { stockItemId: string, storeId: string, recordId: string }) => {
        try {
            const result = await deleteStockItem({ stockItemId, storeId, recordId });
            if ('data' in result && result.data) {
                dispatch(deleteStockItemFromRecord({ stockItemId, recordId }));
            } else if ('error' in result) {
                console.error('Deletion failed', result.error);
            }
        } catch (error) {
            console.error('An error occurred during deletion', error);
        }
    };

    // TODO stockItemNotifications
    useEffect(() => {
        if (createStockItemNotification.isSuccess) {
            setShowCreateStockItemForm(false)
        }
        if (stockItemResponseData) {
            dispatch(addStockItemToRecord({ recordId, newStockItem: stockItemResponseData }));
            dispatch(clearCreateStockItem())
        }
    }, [createStockItemNotification, dispatch, recordId, stockItemResponseData]);


    return (
        <>
            <DataLoader
                isLoading={isLoading}
                error={error}
                data={response}
                render={() => {
                    const conditions = response.data;
                    return (
                        <div>
                            <button className={bigButtonStyleLight} onClick={() => setShowCreateStockItemForm(!showCreateStockItemForm)}>
                                {showCreateStockItemForm ? 'Cancel' : 'Add New Stock'}
                            </button>
                            {showCreateStockItemForm && <CreateStockItemForm conditions={conditions} storeId={user!.store!} recordId={recordId} />}
                            {stock.map((item: any, index: number) => {
                                const currentItemCondition = conditions.find((c: any) => c.condition === item.condition.condition);
                                return (
                                    <div className="p-2" key={item._id}>
                                        <hr />
                                        <p className="mt-1">Stock's ID: {item._id}</p>
                                        <p>
                                            Condition:
                                            {editMode === item._id ? (
                                                <select
                                                    defaultValue={`${currentItemCondition._id},${currentItemCondition.condition}`}
                                                    className="ml-2 border rounded p-1"
                                                    onChange={(e) => handleConditionChange(e, item._id)}
                                                >
                                                    {response?.data.map((condition: any) => (
                                                        <option key={condition._id} value={[condition._id, condition.condition]}>{condition.condition}</option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <span className="ml-2">{item.condition.condition}</span>
                                            )}
                                        </p>
                                        <p>
                                            Price:
                                            {editMode === item._id ? (
                                                <input
                                                    className="ml-2 border rounded p-1"
                                                    type="number"
                                                    defaultValue={item.price}
                                                    onChange={(e) => handlePriceChange(e, item._id)}
                                                />
                                            ) : (
                                                <span className="ml-2">{item.price}€</span>
                                            )}
                                        </p>
                                        {editMode === item._id ? (
                                            <>
                                                <button className={smallButtonStyleLight} onClick={() => toggleEditMode(null)}>Cancel</button>
                                                <button className={`ml-2 ${smallButtonStyleLight}`} onClick={async () => {
                                                    // TODO refactor update stock onClick
                                                    let updatePayload: UpdateStockItemPayload = { "stockItemId": item._id };

                                                    let conditionId;
                                                    let condition;

                                                    if (selectedCondition) {
                                                        [conditionId, condition] = selectedCondition.split(',');
                                                    }

                                                    if (conditionId) {
                                                        updatePayload = { ...updatePayload, conditionId };
                                                    }

                                                    if (newPrice && newPrice > 0 && newPrice !== item.price) {
                                                        updatePayload = { ...updatePayload, price: newPrice };
                                                    } else {
                                                        updatePayload = { ...updatePayload, price: item.price };
                                                    }

                                                    if (!newPrice && !conditionId) {
                                                        toggleEditMode(null);
                                                        return;
                                                    }

                                                    const result = await updateStockItem(updatePayload)

                                                    if ('data' in result && result.data.status === "success") {
                                                        dispatch(updateStockItemInStock({ stockItemId: item._id, recordId, stockItem: { ...result.data.data, condition: { _id: conditionId, condition } } }));
                                                        // TODO: Display success message for updating stock item
                                                        toggleEditMode(null);
                                                    } else if ('error' in result) {
                                                        console.error("Update failed", result.error);
                                                        // TODO: Display error message
                                                    }
                                                }}>Save</button>
                                            </>
                                        ) : (
                                            <>
                                                    <button className={smallButtonStyleLight} onClick={() => toggleEditMode(item._id)}>Edit</button>
                                                    <button className={`ml-2 ${smallButtonStyleLight}`} onClick={() => handleDelete({ stockItemId: item._id, storeId: user!.store!, recordId })}>Delete</button>
                                            </>
                                        )}
                                    </div>
                                )
                            })}
                            <hr />
                            <br />
                        </div>
                    )
                }}
            />
        </>
    );
}

export default StockDisplay;

