import { useTheme } from "../../contexts/ThemeContext";

const imageUrl = process.env.REACT_APP_GR_IMAGES_BASE_URL

function ThemeButton() {
    const { theme, toggleTheme } = useTheme();
    return (
        <button onClick={toggleTheme}>
            {theme === 'dark' ?
                <img src={`${imageUrl}/gr-dark.png`} alt="Light Mode" className="w-5" /> :
                <img src={`${imageUrl}/gr-light.png`} alt="Dark Mode" className="w-5" />}
        </button>
    )
}

export default ThemeButton
