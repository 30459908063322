import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { selectedCondition: any | null } = {
  selectedCondition: null,
};

export const conditionSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setSelectedCondition: (state, action: PayloadAction<any>) => {
      state.selectedCondition = action.payload;
    },
    clearSelectedConditionId: (state, _action) => {
      state.selectedCondition = null;
    },
  },
});

export const { setSelectedCondition, clearSelectedConditionId } =
  conditionSlice.actions;

export default conditionSlice.reducer;
