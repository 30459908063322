import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { newPrice: number | null } = {
  newPrice: null,
};

export const priceSlice = createSlice({
  name: "price",
  initialState,
  reducers: {
    setNewPrice: (state, action: PayloadAction<any>) => {
      state.newPrice = action.payload;
    },
    clearNewPrice: (state, _action) => {
      state.newPrice = null;
    },
  },
});

export const { setNewPrice, clearNewPrice } = priceSlice.actions;

export default priceSlice.reducer;
