import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../utils/utils";

const baseUrl = apiUrl + process.env.REACT_APP_GR_STORES_PATH;
const stockItemPath = process.env.REACT_APP_GR_STOCKITEMS_PATH;

export const storesApi = createApi({
  reducerPath: "storesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
  }),
  endpoints: (builder) => ({
    getStoreById: builder.query({
      query: ({ jwToken, storeId }) => ({
        url: `/${storeId}`,
        headers: {
          Authorization: `Bearer ${jwToken}`,
        },
      }),
    }),
    updateStockItem: builder.mutation({
      query: ({ jwToken, stockItemId, conditionId, price }) => ({
        url: `${stockItemPath}/${stockItemId}`,
        method: "PUT",
        body: { conditionId, price },
        headers: {
          Authorization: `Bearer ${jwToken}`,
        },
      }),
    }),
    createStockItem: builder.mutation({
      query: ({ jwToken, recordId, storeId, conditionId, price }) => ({
        url: `${stockItemPath}`,
        method: "POST",
        body: { recordId, storeId, conditionId, price },
        headers: {
          Authorization: `Bearer ${jwToken}`,
        },
      }),
    }),
    deleteStockItem: builder.mutation({
      query: ({ jwToken, stockItemId, storeId, recordId }) => ({
        url: `${stockItemPath}/${stockItemId}`,
        method: "DELETE",
        body: { recordId, storeId },
        headers: {
          Authorization: `Bearer ${jwToken}`,
        },
      }),
    }),
  }),
});

export const {
  useGetStoreByIdQuery,
  useUpdateStockItemMutation,
  useCreateStockItemMutation,
  useDeleteStockItemMutation,
} = storesApi;
