import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState, SortState } from "../types/Types";

const initialState: SortState = {
  price: "asc",
};

const sortSlice = createSlice({
  name: "sort",
  initialState,
  reducers: {
    setSortPrice(state, action: PayloadAction<SortState>) {
      state.price = action.payload.price;
    },
  },
});

export const sortBy = (state: RootState) => state.sort;

export const { setSortPrice } = sortSlice.actions;

export default sortSlice.reducer;
