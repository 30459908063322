import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { recordsApi } from "./recordsApi";
import { userApi } from "./userApi";
import { ordersListsApi } from "./orderListsApi";

import cartReducer from "./cartSlice";
import profileReducer from "./profileSlice";
import stockItemResponseReducer from "./stockItemResponseSlice";
import priceReducer from "./priceSlice";
import storeReducer from "./storeSlice";
import conditionReducer from "./conditionSlice";
import pageReducer from "./pageSlice";
import sortReducer from "./sortSlice";
import priceRangeReducer from "./priceRangeSlice";
import categoriesReducer from "./categoriesSlice";
import notificationReducer from "./notificationSlice";
import authReducer from "./authSlice";
import { paymentsApi } from "./paymentsApi";
import { storesApi } from "./storesApi";
import { conditionsApi } from "./conditionsApi";

export const store = configureStore({
  reducer: {
    [recordsApi.reducerPath]: recordsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [ordersListsApi.reducerPath]: ordersListsApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [storesApi.reducerPath]: storesApi.reducer,
    [conditionsApi.reducerPath]: conditionsApi.reducer,
    cart: cartReducer,
    profile: profileReducer,
    sort: sortReducer,
    page: pageReducer,
    priceRange: priceRangeReducer,
    categories: categoriesReducer,
    notification: notificationReducer,
    auth: authReducer,
    condition: conditionReducer,
    store: storeReducer,
    price: priceReducer,
    stockItemResponse: stockItemResponseReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      recordsApi.middleware,
      userApi.middleware,
      ordersListsApi.middleware,
      paymentsApi.middleware,
      storesApi.middleware,
      conditionsApi.middleware
    ),
});

setupListeners(store.dispatch);
