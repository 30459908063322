import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProfileState {
  firstName: string;
  lastName: string;
}

const initialState: ProfileState = {
  firstName: "",
  lastName: "",
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setUserInfo: (
      state,
      action: PayloadAction<{ firstName: string; lastName: string }>
    ) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    },
  },
});

export const { setUserInfo } = profileSlice.actions;

export default profileSlice.reducer;
