import { useForm } from "react-hook-form"
import { useCreateStockItemMutation } from "../../store/storesApi";
import Form from "../shared/Form";
import { setCreateLoveTokenNotification, clearCreateLoveTokenNotification } from "../../store/notificationSlice";
import InputField from "../shared/InputField";
import { setCreateStockItem } from "../../store/stockItemResponseSlice";

function CreateStockForm({ conditions, storeId, recordId }: { conditions: any[], storeId: string, recordId: string }) {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [createStockItem, { isLoading }] = useCreateStockItemMutation();

    return (
        <Form
            onSubmit={handleSubmit}
            isLoading={isLoading}
            callback={createStockItem}
            successMessage="Stock item created successfully! Redirecting to its page..."
            errorMessage="Error while creating stock item. Please try again."
            setNotification={setCreateLoveTokenNotification}
            clearNotification={clearCreateLoveTokenNotification}
            setResponseData={setCreateStockItem}
        >
            <InputField
                id="conditionId"
                label="Condition"
                inputType="select"
                register={register}
                required={true}
                errors={errors}
                options={conditions.map((condition: any) => `${condition._id},${condition.condition}`)}
            />
            <InputField
                id="price"
                label="Price"
                type="number"
                register={register}
                required={true}
                errors={errors}
            />
            <div className="hidden">
                <InputField
                    id="storeId"
                    label="Store ID"
                    type="hidden"
                    defaultValue={storeId}
                    register={register}
                    required={true}
                    errors={errors}
                />
            </div>
            <div className="hidden">
                <InputField
                    id="recordId"
                    label="Record ID"
                    type="hidden"
                    defaultValue={recordId}
                    register={register}
                    required={true}
                    errors={errors}
                />
            </div>
        </Form>
    )
}

export default CreateStockForm
