import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import PageHeader from "../components/headers/PageHeader";
import { useDeleteOrderFromOrderListMutation, useGetOrderListQuery, usePatchAndCreateOrderListMutation } from "../store/orderListsApi"
import { useCreatePaymentIntentMutation } from "../store/paymentsApi";
import DataLoader from "../utils/DataLoader";
import { bigButtonStyleLight, cardElementStyle, getUserFromLocalStorage, setUserInLocalStorage, smallButtonStyleLight } from "../utils/utils";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { removeOrder } from "../store/cartSlice";

const imageUrl = process.env.REACT_APP_GR_IMAGES_BASE_URL

// TODO handle stripePromise undefined
const stripePromise = loadStripe(process.env.REACT_APP_GR_STRIPE_PUBLISHABLE_KEY!);

function Cart() {
  return (
    <Elements stripe={stripePromise}>
      <CartComponent />
    </Elements>
  );
}

function CartComponent() {
  const user = getUserFromLocalStorage()
  const { data: response, error, isLoading } = useGetOrderListQuery({ jwtToken: user!.token, userId: user!.userId, orderListId: user!.currentOrderList });
  const [createPaymentIntent] = useCreatePaymentIntentMutation();
  const [patchAndCreateOrderList] = usePatchAndCreateOrderListMutation();
  const [deleteOrderFromOrderList] = useDeleteOrderFromOrderListMutation();
  const [copyButtonText, setCopyButtonText] = useState('📋');
  const ordersInCart = useSelector((state: any) => state.cart.orders);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const orderSum = ordersInCart.reduce((acc: number, order: { stockItem: { price: number } }) => acc + order.stockItem.price, 0);

  const handleCheckout = async () => {
    try {
      const { clientSecret } = await createPaymentIntent({ jwtToken: user!.token, userId: user!.userId, orderSum }).unwrap();

      if (!stripe || !elements) {
        console.error("Stripe has not loaded yet.");
        return;
      }

      const cardElement = elements.getElement(CardElement);

      if (cardElement) {
        const paymentResult = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement
          },
        });
        if (paymentResult.error) {
          console.error(`Payment failed: ${paymentResult.error.message}`);
          // TODO Display an error message to the user
        } else {
          if (paymentResult.paymentIntent.status === 'succeeded') {
            // TODO Display a success message to the user
            const patchResponse = await patchAndCreateOrderList({ jwtToken: user!.token, userId: user!.userId, orderListId: user!.currentOrderList }).unwrap();
            if (patchResponse.status === "success") {
              setUserInLocalStorage({ userId: user!.userId, role: user!.role, userName: user!.userName, currentOrderList: patchResponse.data.newOrderList });
              navigate('/checkout', { state: { paidOrder: ordersInCart } });
            }
          }
        }
      }
    } catch (error) {
      console.error("Failed to create payment intent", error);
      // TODO Display an error message to the user
    }
  };

  const handleDeleteOrderFromOrderList = async (orderId: string) => {
    try {
      const response = await deleteOrderFromOrderList({ jwtToken: user!.token, userId: user!.userId, orderListId: user!.currentOrderList, orderId }).unwrap();
      console.log("response", response);
      if (response.status === "success") {
        dispatch(removeOrder(orderId));
        // TODO Display a success message to the user
      }
    } catch (error) {
      console.error("Failed to delete order from order list", error);
      // TODO Display an error message to the user
    }
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText("4242 4242 4242 4242").then(() => {
      setCopyButtonText('Copied!');
      setTimeout(() => setCopyButtonText('📋'), 2000);
    }, (err) => {
      console.error('Failed to copy credit card number: ', err);
    });
  };

  if (!ordersInCart) {
    return (
      <div>
        <PageHeader title="Your Cart" subtitle="" />
        <p>Your cart is empty.</p>
      </div>
    )
  }

  return (
    <section className="p-4 w-full lg:max-w-6xl mx-auto section-container--min-height">
    <DataLoader
      isLoading={isLoading}
      error={error}
      data={response}
      render={() => {
        if (ordersInCart.length === 0) {
          return (
            <>
              <PageHeader title="Cart" subtitle="Your cart is empty" />
              {/* TODO style to go crate digging */}
              <div className="text-center">
                <button
                  className={`${bigButtonStyleLight} mb-4`}
                  onClick={() => navigate("/crate")}
                >
                  Start crate digging now
                </button>
              </div>
            </>
          )
        }
        return (
          <div>
            <section className="p-4 w-full lg:max-w-6xl mx-auto section-container--min-height">
              <PageHeader title="Cart" subtitle="Records waiting for checkout:" />
              <br />
              {ordersInCart.map((order: any, i: number) => {
                return (
                  <React.Fragment key={`${order._id}-${i}`}>
                    <div className="p-2" key={order._id}>
                      <center>
                        <Link to={`/records/${order.record._id}`}>
                          <img src={`${imageUrl}/${order.record._id}-lq.avif`} alt="album cover" />
                        </Link>                        <p><i>{order.record.title}</i> by <b>{order.record.artist}</b></p>
                        <p>{order.stockItem.price}€ from <b>{order.store.name}</b></p>
                        <button className={smallButtonStyleLight} onClick={() => handleDeleteOrderFromOrderList(order._id)}>Delete from cart</button>
                      </center>
                    </div>
                    {i < ordersInCart.length - 1 ? <><br /><hr /><br /></> : null}
                  </React.Fragment>
                )
              })}
              <br />
              {/* TODO styles for Total */}
              <div className="text-center text-white text-xl border p-4 background--licorice border-gray-700">
                <p>Total: {orderSum}€</p>
              </div>
              <div className="text-center text-white p-4 background--licorice">
                <p>Credit card information:</p>
              </div>
              <div className="border p-4 background--licorice border-gray-700">
                <CardElement options={cardElementStyle} />
              </div>
              <div className="text-center p-4 background--licorice border border-gray-700">
                <p className="text--gr-orange text-m mb-2">You can checkout with Stripe's fake Credit Card:</p>
                <div className="flex justify-center items-center">
                  <p className="text--gr-orange text-m font-bold">4242 4242 4242 4242</p>
                  <button onClick={handleCopyToClipboard} className="ml-2 text--gr-orange">{copyButtonText}</button>
                </div>
                <p className="mt-2 text--gr-orange text-m">Any numbers will do for MM/YY, CVC and ZIP!</p>
              </div>
              {/* TODO handle processing checkout */}
              <center>
                <button className={bigButtonStyleLight} onClick={handleCheckout}>Checkout</button>
              </center>
            </section>
          </div>
        )
      }}
    />
    </section>
  )
}

export default Cart
