import { useState } from "react";

import { InputFieldProps } from "../../types/Types";
import { convertSelectedLabelToBoolean } from "../../utils/utils";

const InputField = ({ id, label, type, register, required, errors, options, defaultValue, inputType }: InputFieldProps & { inputType?: string }) => {
    let inputElement;

    const [categoryIndex, setCategoryIndex] = useState<number>(0);

    if (inputType === "select" && options && options.length > 0) {
        inputElement = (
            <select id={id} {...register(id, { required })} defaultValue={defaultValue} className={`h-10 px-3 py-2 mt-1 block w-full text-xl border background--tropical-indigo text--light-cyan focus:outline-none ${errors[id] ? 'border-licorice' : 'border-light-cyan'}`}>
                {options.map((option, index) => {
                    const [conditionId, condition] = option.split(',');
                    return (
                        <option key={index} value={conditionId}>{condition}</option>
                    )
                })}
            </select>
        )
    } else if (options && options.length > 0) {
        inputElement = (
            <>
                {options.map((option, index) => (
                    <div key={index} className="flex items-center">
                        <input
                            id={`${id}-${index}`}
                            type={type}
                            value={option}
                            {...register(`${id}.${index}`)}
                            defaultChecked={convertSelectedLabelToBoolean(defaultValue as string[], categoryIndex, option, setCategoryIndex)}
                            className="mr-2"
                        />
                        <label htmlFor={`${id}-${index}`}>{option}</label>
                    </div>
                ))}
            </>
        );
    } else {
        inputElement = (
            // todo selected input style
            <input
                type={type}
                id={id}
                {...register(id, { required })}
                className={`h-10 px-3 py-2 mt-1 block w-full text-xl border background--tropical-indigo text--light-cyan focus:outline-none ${errors[id] ? 'border-licorice' : 'border-light-cyan'}`}
                defaultValue={defaultValue}
            />
        );
    }

    return (
        <div className="mb-4">
            <label htmlFor={id} className="block text-xl">{label}:</label>
            {inputElement}
            {errors[id] && <div className="text-center text--gr-orange background--licorice p-1 text-m">{label} is required</div>}
        </div>
    );
};

export default InputField;
