import { useEffect } from "react";
import { useGetOrderListQuery } from "../../store/orderListsApi";
import DataLoader from "../../utils/DataLoader";
import { getUserFromLocalStorage } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { addOrder } from "../../store/cartSlice";

function ReadStockItemsFromCart({ loggedUser }: { loggedUser: boolean }) {
  const user = getUserFromLocalStorage()
  const { data: response, error, isLoading } = useGetOrderListQuery({ userId: (user ? user.userId : null), orderListId: (user ? user.currentOrderList : null) });
  const dispatch = useDispatch();
  const ordersInCart = useSelector((state: any) => state.cart.orders);

  useEffect(() => {
    if (response && response.data && response.data.orders.length > 0) {
      response.data.orders.forEach((order: any) => {
        const transformedOrder = {
          _id: order.stockItem._id,
          record: {
            _id: order.record._id,
            title: order.record.title,
            artist: order.record.artist,
          },
          stockItem: {
            _id: order.stockItem._id,
            price: order.stockItem.price,
          },
          store: {
            _id: order.store._id,
            name: order.store.name,
          },
        };

        const isOrderInCart = ordersInCart.some((cartOrder: any) => cartOrder._id === transformedOrder._id);

        if (!isOrderInCart) {
          dispatch(addOrder(transformedOrder));
        }
      });
    }
  }, [response, dispatch, ordersInCart]);

  if (!ordersInCart) {
    return <DataLoader isLoading={isLoading} error={error} data={response} render={() => <p>💀</p>} />;
  }

  return (
    <>
      {ordersInCart.length === 0 ? <b className="mr-2 text-white">{`(0)`}</b> : <b className="mr-2 text-white">({ordersInCart.length})</b>}
    </>
  )
}

export default ReadStockItemsFromCart
