import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import PageHeader from "../components/headers/PageHeader";
import CategoriesSection from "../components/cratedigging/CategoriesSection";
import SortInput from "../components/cratedigging/SortInput";
import NotificationBox from "../components/shared/NotificationBox";
import RecordsPreviewSection from "../components/cratedigging/RecordsPreviewSection";
import PriceRangeInput from "../components/cratedigging/PriceRangeInput";
import { applyPriceRange } from "../store/priceRangeSlice";
import { toggleCategory } from "../store/categoriesSlice";
import { useFetchRecordsQuery } from "../store/recordsApi";
import { RootState } from "../types/Types";
import { updateCurrentPage } from "../store/pageSlice";

function CrateDigging({ loggedUser }: { loggedUser: boolean }) {
    const selectedCategories = useSelector((state: RootState) => state.categories.selectedGenres);
    const priceRange = useSelector((state: RootState) => state.priceRange);
    const pageState = useSelector((state: RootState) => state.page);
    const sort = useSelector((state: RootState) => state.sort);

    const dispatch = useDispatch();

    const { refetch: refetchRecords } = useFetchRecordsQuery({ max: priceRange.max, min: priceRange.min, genres: selectedCategories, page: pageState.current, sortPrice: sort.price });

    const handleCategorySelect = (category: string) => {
        dispatch(toggleCategory(category));
    };

    const handlePriceRangeChange = (min: number, max: number) => {
        dispatch(applyPriceRange({ min, max }));
    }

    useEffect(() => {
        refetchRecords();
    }, [selectedCategories, refetchRecords, priceRange.min, priceRange.max, pageState, sort]);

    return (
        <section className="p-4 w-full lg:max-w-6xl mx-auto section-container--min-height">
            <PageHeader
                title="Crate Digging"
                subtitle="Are your golden ears craving for new sounds?<br />Time to find your next record(s)!"
            />
            {loggedUser ? null :
                // TODO add extra link to NotificationBox
                <NotificationBox
                    message="Expand your record collection."
                    to="/register"
                    toMessage="Register or login to shop!"
                />
            }
            <CategoriesSection
                selectedCategories={selectedCategories}
                handleCategorySelect={handleCategorySelect}
            />
            <SortInput />
            <PriceRangeInput priceRange={priceRange} onPriceRangeChange={handlePriceRangeChange} />
            <br />
            <RecordsPreviewSection selectedCategories={selectedCategories} priceRange={priceRange} page={pageState.current} sortPrice={sort.price} loggedUser={true} />
            {/* TODO better styling */}
            <div>
                <center>
                    <div>
                        {pageState.current !== 1 ?
                            <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2" onClick={() => {
                                dispatch(updateCurrentPage(pageState.current - 1))
                            }}>
                                Previous
                            </button>
                            : null}
                        {pageState.current !== pageState.total ?
                            <button className="ml-2 mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2" onClick={() => {
                                dispatch(updateCurrentPage(pageState.current + 1))
                            }}>
                                Next
                            </button>
                            : null}
                    </div>
                    <div><small>Page {pageState.current} of {pageState.total}</small></div>
                </center>
            </div>

        </section >
    )
}

export default CrateDigging