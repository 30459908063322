import React, { useEffect } from "react";
import RecordDisplay from "../components/cratedigging/RecordDisplay";
import HeaderTwo from "../components/headers/HeaderTwo";
import PageHeader from "../components/headers/PageHeader";
import StockDisplay from "../components/mystore/StockDisplay";
import { useGetStoreByIdQuery } from "../store/storesApi";
import DataLoader from "../utils/DataLoader";
import { getUserFromLocalStorage } from "../utils/utils";
import { setStoreDetails } from "../store/storeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bigButtonStyleLight } from "../utils/utils";

function MyStore() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = getUserFromLocalStorage()
  const { data: response, error, isLoading, refetch } = useGetStoreByIdQuery({ token: user?.token, storeId: user?.store });
  const storeFromStoreSlice = useSelector((state: any) => state.store.storeDetails);

  useEffect(() => {
    if (response?.data && !storeFromStoreSlice) {
      dispatch(setStoreDetails(response.data));
    }
  }, [response, dispatch, storeFromStoreSlice, refetch])

  if (!storeFromStoreSlice) {
    return <DataLoader isLoading={isLoading} error={error} data={null} emptyMessage="Loading store details..." render={() => <></>} />;
  }

  return (
    <section className="p-4 w-full lg:max-w-6xl mx-auto section-container--min-height">
      <PageHeader title="My store" subtitle={storeFromStoreSlice.name} />
      <div className="mt-4">
        <p><strong>Location:</strong> {storeFromStoreSlice.location}</p>
        <p><strong>Website:</strong> <a href={storeFromStoreSlice.website} target="_blank" rel="noopener noreferrer">{storeFromStoreSlice.website}</a></p>
        <p><strong>Rating:</strong> {storeFromStoreSlice.rating}</p>
        <p><strong>Description:</strong> {storeFromStoreSlice.description}</p>
        <p><strong>Contact Email:</strong> {storeFromStoreSlice.contactEmail}</p>
        <p><strong>Phone Number:</strong> {storeFromStoreSlice.phoneNumber}</p>
        <p><strong>Opening Hours:</strong> {storeFromStoreSlice.openingHours}</p>
        <p><strong>Return Policy:</strong> {storeFromStoreSlice.returnPolicy}</p>
        <p><strong>Featured Items:</strong> {storeFromStoreSlice.featuredItems.join(', ')}</p>
      </div>
      <br />
      <center>
        <HeaderTwo title="Your records in stock" />
        {storeFromStoreSlice.recordsInStock.map((record: any) => {
          return (
            <React.Fragment key={record.record._id}>
              <RecordDisplay record={record.record} coverSize="lq" />
              <StockDisplay stock={record.stockItems} recordId={record.record._id} />
            </React.Fragment>
          )
        })}
        <button className={bigButtonStyleLight} onClick={() => navigate('/addrecord')}>
          Add a New Record to Your Store
        </button>
      </center>
    </section>
  )
}

export default MyStore
