import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { selectedGenres: string[] } = {
  selectedGenres: [],
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    toggleCategory: (state, action: PayloadAction<string>) => {
      const category = action.payload;
      if (state.selectedGenres.includes(category)) {
        state.selectedGenres = state.selectedGenres.filter(
          (c) => c !== category
        );
      } else {
        state.selectedGenres.push(category);
      }
    },
  },
});

export const { toggleCategory } = categoriesSlice.actions;

export default categoriesSlice.reducer;
