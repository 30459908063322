import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../utils/utils";

const baseUrl =
  apiUrl +
  process.env.REACT_APP_GR_RECORDS_PATH +
  process.env.REACT_APP_GR_CONDITIONS_PATH;

export const conditionsApi = createApi({
  reducerPath: "conditionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
  }),
  endpoints: (builder) => ({
    getConditions: builder.query({
      query: () => "",
    }),
  }),
});

export const { useGetConditionsQuery } = conditionsApi;
