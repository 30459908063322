import { Link } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";
import { NotificationBoxProps } from '../../types/Types';

function NotificationBox({ message, to, toMessage, extraLine }: NotificationBoxProps) {
    const { theme } = useTheme();

    return (
        <div className="flex items-center justify-center mt-2">
            <div className={`p-4 mx-auto inline-block border ${theme === 'light' ? 'border-gray-300 bg-white text-gray-800' : 'border-gray-700 bg-gray-800 text-gray-100'} text-center shadow-lg max-w-md mb-4`}>
                <p>{message}</p>
                {extraLine && <p>{extraLine}</p>}
                {to && (
                    <Link to={to} className={`font-medium ${theme === 'light' ? 'text-blue-600 hover:text-blue-700' : 'text-blue-400 hover:text-blue-300'} underline`}>
                        {toMessage}
                    </Link>
                )}
                {!to && toMessage && <p>{toMessage}</p>}
            </div>
        </div>
    );
}

export default NotificationBox;