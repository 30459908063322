import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { SubmitParams } from "../types/Types";

export interface UserFromLocalStorage {
  userId: string;
  role: string;
  userName: string;
  currentOrderList: string;
  store?: string;
  token?: string;
}

export function getErrorMessage(
  error: FetchBaseQueryError | SerializedError
): string {
  if ("status" in error && error.status === "FETCH_ERROR") {
    return error.error || "Unknown error occurred.";
  } else if ("message" in error) {
    return error.message || "Unknown error occurred.";
  } else {
    return "Unknown error occurred.";
  }
}

// TODO refactor sortLoveTokens to sortRecordsByPrice
export const sortLoveTokens = (records: any[], sortOrder: string) => {
  return [...records].sort((a, b) => {
    const dateA = new Date(a.creationDate);
    const dateB = new Date(b.creationDate);

    return sortOrder === "oldest"
      ? dateA.getTime() - dateB.getTime()
      : sortOrder === "newest"
      ? dateB.getTime() - dateA.getTime()
      : 0;
  });
};

const filterSelectedLabels = (labels: (string | boolean)[]) => {
  return labels.filter((label) => label !== false);
};

export const convertSelectedLabelToBoolean = (
  defaultValue: string[],
  categoryIndex: number,
  option: string,
  setCategoryIndex: React.Dispatch<React.SetStateAction<number>>
) => {
  const isSelected = defaultValue && defaultValue.includes(option);
  if (isSelected && categoryIndex === defaultValue.length) {
    setCategoryIndex(categoryIndex + 1);
  }
  return isSelected;
};

export const handleFormSubmission = async (
  {
    callback,
    method,
    setNotification,
    clearNotification,
    successMessage,
    errorMessage,
    user,
    tokenNumber,
    setResponseData,
  }: SubmitParams,
  data: any
) => {
  try {
    if (user) {
      data = { ...data, createdBy: { ...user } };
    }
    if (data.labels) {
      const selectedLabels = filterSelectedLabels(data.labels);
      data.labels = selectedLabels;
    }
    let result;
    if (tokenNumber) {
      if (method === "PUT") {
        result = await callback({
          tokenNumber,
          updatedLoveToken: { labels: data.labels, phrase: data.phrase },
          jwtToken: data.createdBy.token,
        });
      }
    } else {
      result = await callback(data);
    }
    if (result.error) {
      if (result.error.data.error) {
        throw new Error(result.error.data.error);
      }
      throw new Error(result.error);
    }
    if (result.data.token) {
      localStorage.setItem("token", result.data.token);
    }

    if (setResponseData) {
      setResponseData(result.data);
    }
    setNotification(
      result.data.uri
        ? { message: successMessage, isSuccess: true, uri: result.data.uri }
        : { message: successMessage, isSuccess: true }
    );
    setTimeout(() => {
      clearNotification();
    }, 5000);
  } catch (err) {
    const errorObject = err as Error;
    setNotification({
      message: errorObject.message || errorMessage,
      isSuccess: false,
    });
    setTimeout(() => {
      clearNotification();
    }, 5000);
  }
};

export const setUserInLocalStorage = ({
  userId,
  role,
  userName,
  currentOrderList,
  store,
}: UserFromLocalStorage) => {
  localStorage.setItem("GRuserId", userId);
  localStorage.setItem("GRuserRole", role);
  localStorage.setItem("GRuserName", userName);
  localStorage.setItem("GRcurrentOrderList", currentOrderList);
  if (store) {
    localStorage.setItem("GRstore", store);
  }
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("GRuserId");
  localStorage.removeItem("GRuserRole");
  localStorage.removeItem("GRuserName");
  localStorage.removeItem("GRcurrentOrderList");
  localStorage.removeItem("token");
  if (localStorage.getItem("GRstore")) {
    localStorage.removeItem("GRstore");
  }
};

export const isUserLoggedIn = () => {
  const userId = localStorage.getItem("GRuserId");
  return userId !== null && userId !== undefined;
};

export const getUserFromLocalStorage = () => {
  const userId = localStorage.getItem("GRuserId");
  const role = localStorage.getItem("GRuserRole");
  const userName = localStorage.getItem("GRuserName");
  const currentOrderList = localStorage.getItem("GRcurrentOrderList");
  const token = localStorage.getItem("token");
  const store = localStorage.getItem("GRstore");

  if (!userId || !role || !userName || !token || !currentOrderList) {
    return null;
  }

  const userObject: UserFromLocalStorage = {
    userId: userId,
    role: role,
    userName: userName,
    token: token,
    currentOrderList: currentOrderList,
  };

  if (store) {
    userObject.store = store;
  }

  return userObject;
};

export const getFormSubmitButtonClass = (theme: string): string => {
  return theme === "light"
    ? "background--fairy-tale hover:background--ce-soir text--licorice"
    : "bg-gray-900 hover:bg-gray-800 text-gray-200";
};

export const getFormBackgroundClass = (theme: string): string => {
  return theme === "light"
    ? "background--tropical-indigo"
    : "bg-indigo-900 text-gray-200";
};

export const apiUrl =
  process.env.REACT_APP_GR_API_BASE_URL! +
  process.env.REACT_APP_GR_API_VERSION_PATH;

export const smallButtonStyleLight =
  "mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2";

export const bigButtonStyleLight =
  "mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4";

export const cardElementStyle = {
  style: {
    base: {
      fontSize: "16px",
      color: "#ffffff",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};