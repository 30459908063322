import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../utils/utils";

const baseUrl = apiUrl + process.env.REACT_APP_GR_USERS_PATH;
const orderListsPath = process.env.REACT_APP_GR_ORDERLISTS_PATH;
const ordersPath = process.env.REACT_APP_GR_ORDERS_PATH;

export const ordersListsApi = createApi({
  reducerPath: "ordersListsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
  }),
  endpoints: (builder) => ({
    addOrderToOrderList: builder.mutation({
      query: ({ jwToken, userId, orderListId, record, stock, stockItem }) => ({
        url: `/${userId}${orderListsPath}/${orderListId}`,
        method: "POST",
        body: { record, stock, stockItem },
        headers: {
          Authorization: `Bearer ${jwToken}`,
        },
      }),
    }),
    getOrderList: builder.query({
      query: ({ jwToken, userId, orderListId }) => ({
        url: `/${userId}${orderListsPath}/${orderListId}`,
        headers: {
          Authorization: `Bearer ${jwToken}`,
        },
      }),
    }),
    patchAndCreateOrderList: builder.mutation({
      query: ({ jwToken, userId, orderListId }) => ({
        url: `/${userId}${orderListsPath}/${orderListId}`,
        method: "PATCH",
        body: { userId },
        headers: {
          Authorization: `Bearer ${jwToken}`,
        },
      }),
    }),
    deleteOrderFromOrderList: builder.mutation({
      query: ({ userId, orderListId, orderId, jwToken }) => ({
        url: `/${userId}${orderListsPath}/${orderListId}${ordersPath}/${orderId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwToken}`,
        },
      }),
    }),
    // removeLoveTokenFromList: builder.mutation({
    //   query: ({ loveTokenId, jwToken }) => ({
    //     url: "/",
    //     method: "DELETE",
    //     body: { loveTokenId },
    //     headers: {
    //       Authorization: `Bearer ${jwToken}`,
    //     },
    //   }),
    // }),
    // updateAffectionListOrder: builder.mutation({
    //   query: ({ newOrder, jwToken }) => ({
    //     url: "/",
    //     method: "PUT",
    //     body: { newOrder },
    //     headers: {
    //       Authorization: `Bearer ${jwToken}`,
    //     },
    //   }),
    // }),
  }),
});

export const {
  useAddOrderToOrderListMutation,
  useGetOrderListQuery,
  usePatchAndCreateOrderListMutation,
  useDeleteOrderFromOrderListMutation,
} = ordersListsApi;
