import { useParams } from "react-router-dom";

import { useFetchRecordByIdQuery } from "../store/recordsApi"
import DataLoader from "../utils/DataLoader"
import PageHeader from "../components/headers/PageHeader";
import HeaderTwo from "../components/headers/HeaderTwo";
import React, { useEffect, useState } from "react";
import { getUserFromLocalStorage, smallButtonStyleLight } from "../utils/utils";
import { useAddOrderToOrderListMutation } from "../store/orderListsApi";
import { addOrder } from "../store/cartSlice";
import { useDispatch } from "react-redux";
import NotificationBox from "../components/shared/NotificationBox";

interface Record {
  _id: string;
  title: string;
  artist: string;
  description: string;
  stock: {
    stockItems: Array<{
      _id: string;
      price: number;
      condition: {
        condition: string;
      };
      store: {
        name: string;
      };
    }>;
    _id: string;
  };
}

// TODO refactor SingleRecord component

const imageUrl = process.env.REACT_APP_GR_IMAGES_BASE_URL

function SingleRecord({ loggedUser }: { loggedUser: boolean }) {
  const user = getUserFromLocalStorage();
  const { id } = useParams();
  const { data: response, isLoading, error } = useFetchRecordByIdQuery(id!);
  const [handleAddStockItemToBackendCart] = useAddOrderToOrderListMutation();
  const [recordInState, setRecordInState] = useState<Record | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (response) {
      const [record] = response;
      setRecordInState(record);
    }
  }, [response]);

  if (!recordInState) {
    return <DataLoader isLoading={isLoading} error={error} data={response} render={() => <p>💀</p>} />;
  }

  const handleAddStockItemToCart = async (stockItem: any) => {
    try {
      const mutationResult = await handleAddStockItemToBackendCart({
        userId: user!.userId, jwToken: user!.token, orderListId: user!.currentOrderList, record: recordInState._id, stock: stockItem.stockId, stockItem: stockItem.stockItemId
      });

      if ("data" in mutationResult && mutationResult.data) {
        const dataAttempt = {
          _id: stockItem.stockId,
          record: {
            _id: recordInState._id,
            title: recordInState.title,
            artist: recordInState.artist,
          },
          stockItem: {
            _id: stockItem.stockItemId,
            price: stockItem.stockPrice,
          },
          store: {
            _id: stockItem.stockStoreId,
            name: stockItem.stockStore,
          }
        }
        dispatch(addOrder(dataAttempt));
      } else if ("error" in mutationResult) {
        console.error("Error adding stock item to cart:", mutationResult.error);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  return (
    <section className="p-4 w-full lg:max-w-6xl mx-auto section-container--min-height">
      <DataLoader
        isLoading={isLoading}
        error={error}
        data={recordInState}
        emptyMessage={`Record with id ${id} doesn't exist!`}
        render={() => 
            <>
            <div key={recordInState._id}>
              <PageHeader title={recordInState.title} subtitle={`by ${recordInState.artist}`} />
                <center><img
                  className="mt-2 w-full md:w-2/5 object-cover object-center"
                src={`${imageUrl}/${recordInState._id}-hq.avif`} alt="album cover">
                </img>
                <HeaderTwo title={recordInState.description} />
                  <div className="text-2xl mt-2">
                    <div className="mb-2">
                      <b>Available records for purchase:</b>
                    </div>
                  {recordInState.stock.stockItems.map((stock: any, index: number) => {
                    return (
                      <React.Fragment key={stock._id}>
                        <div>
                          {stock.price}€ – {stock.condition.condition}<br />
                          At {stock.store.name}<br />
                        </div>
                        {loggedUser ?
                          <>
                            <button className={smallButtonStyleLight} onClick={() => handleAddStockItemToCart({ stockId: recordInState.stock._id, stockPrice: stock.price, stockStore: stock.store.name, stockStoreId: stock.store._id, stockItemId: stock._id })}> Add to cart</button>
                          </>
                          : null}
                        {recordInState.stock.stockItems.length !== (index + 1) ? <hr className="mt-4 mb-2" /> : null}
                      </React.Fragment>
                    )
                  })}
                </div>
                <br />
                {!loggedUser && <NotificationBox
                  message="Expand your record collection."
                  to="/register"
                  toMessage="Register or login to shop!"
                />}
                </center>
              </div>
            </>
        }
      />
    </section>
  )
}

// const transformedOrder = {
//   _id: order.stockItem._id,
//   record: {
//     _id: order.record._id,
//     title: order.record.title,
//     artist: order.record.artist,
//   },
//   stockItem: {
//     _id: order.stockItem._id,
//     price: order.stockItem.price,
//   },
//   store: {
//     _id: order.store._id,
//     name: order.store.name,
//   },
// }

export default SingleRecord

