import { PriceRangeState } from "../../types/Types";

interface PriceRangeInputProps {
    priceRange: PriceRangeState;
    onPriceRangeChange: (min: number, max: number) => void;
}

// TODO debounce
function PriceRangeInput({ priceRange, onPriceRangeChange }: PriceRangeInputProps) {
    const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const min = parseInt(e.target.value);
        onPriceRangeChange(min, priceRange.max);
    };

    const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const max = parseInt(e.target.value);
        onPriceRangeChange(priceRange.min, max);
    };

    return (
        <div className="flex mt-5 mb-4">
            <div>
                <label htmlFor="min-price" className="bebas-neue-regular text-3xl text--light-cyan">Min Price: </label>
            <input
                    type="number"
                    id="min-price"
                value={priceRange.min || 0}
                    onChange={handleMinChange}
                    className="text-xl outline-none background--tropical-indigo border px-4 py-2 text--light-cyan mr-2"
                />
            </div>
            <div>
                <label htmlFor="max-price" className="bebas-neue-regular text-3xl text--light-cyan">Max Price: </label>
            <input
                type="number"
                id="max-price"
                value={priceRange.max === Infinity ? "" : priceRange.max}
                    onChange={handleMaxChange}
                    className="text-xl outline-none background--tropical-indigo border px-4 py-2 text--light-cyan"
                />
            </div>
        </div>
    );
}

export default PriceRangeInput;