import { Navigate, Route, Routes } from "react-router-dom"
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from "@react-oauth/google";

import NavBar from "./components/navbar/NavBar";
import Footer from "./components/shared/Footer";
import CrateDigging from "./pages/CrateDigging";
import SingleLoveToken from "./pages/SingleRecord";
import Login from "./pages/Login";
import Auth from "./pages/Auth";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import Logout from "./pages/Logout";
import "./styles/styles.scss";
import { useTheme } from "./contexts/ThemeContext";
import { useLoggedInState } from "./utils/useLoggedInState";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import MyStore from "./pages/MyStore";
import AddRecord from "./pages/AddRecord";
import { getUserFromLocalStorage } from "./utils/utils";
import Home from "./pages/Home";

function App() {
  const loggedUser = useLoggedInState();
  const user = getUserFromLocalStorage()

  const { theme } = useTheme();
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  return <div className={`App theme${theme === 'light' ? '--light' : '--dark'}`}>
    <ToastContainer autoClose={4000} position="top-center" transition={Slide} />
    <NavBar loggedUser={loggedUser} />
    <main>
      <Routes>
        <Route path="/addrecord" element={(loggedUser && user?.role === "Store") ? <AddRecord /> : <Navigate to="/register" />} />
        <Route path="/mystore" element={(loggedUser && user?.role === "Store") ? <MyStore /> : <Navigate to="/register" />} />
        <Route path="/checkout" element={loggedUser ? <Checkout /> : <Navigate to="/register" />} />
        <Route path="/cart" element={loggedUser ? <Cart /> : <Navigate to="/login" />} />
        <Route path="/crate" element={<CrateDigging loggedUser={loggedUser} />} />
        <Route path="/authenticate" element={<Auth />} />
        <Route path="/profile" element={loggedUser ? <Profile /> : <Navigate to="/login" />} />
        <Route path="/records/:id" element={<SingleLoveToken loggedUser={loggedUser} />} />
        <Route path="/register" element={loggedUser ? <Navigate to="/profile" /> : <Register />} />
        <Route path="/login" element={loggedUser ? <Navigate to="/profile" /> : <GoogleOAuthProvider clientId={googleClientId!}><Login /></GoogleOAuthProvider>} />
        <Route path="/logout" element={loggedUser ? <Logout /> : <Navigate to="/profile" />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </main>
    <Footer />
  </div>;
}

export default App;