import { useLocation } from "react-router-dom";
import PageHeader from "../components/headers/PageHeader";
import NotificationBox from "../components/shared/NotificationBox";
import { useDispatch } from "react-redux";
import { cleanCart } from "../store/cartSlice";

const imageUrl = process.env.REACT_APP_GR_IMAGES_BASE_URL

function Checkout() {
  const location = useLocation();
  const state = location.state;
  const paidOrder = state?.paidOrder;
  const dispatch = useDispatch();
  dispatch(cleanCart());

  return (
    <section className="p-4 w-full lg:max-w-6xl mx-auto section-container--min-height">

      <PageHeader title="Checkout" subtitle="Your order was successfully processed!" />
      {paidOrder && paidOrder.length > 0 ? (
        <>
          <br />
          <div className="text-center">
            <ul>
              {paidOrder.map((order: any, index: number) => (
                <li key={index}>
                  <center>
                    <img
                      src={`${imageUrl}/${order.record._id}-lq.avif`} alt="album cover">
                    </img>
                  </center>
                  <p><i>{order.record.title}</i> by <b>{order.record.artist}</b></p>
                  <p>{order.stockItem.price}€ from <b>{order.store.name}</b></p>
                  {index < paidOrder.length - 1 ? <><br /><hr /><br /></> : <br />}
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : (
        <p>No orders to display.</p>
      )}
      <NotificationBox message="Thank you for using Golden Rack!" extraLine="Greetings, Danilo :)" />
    </section>
  );
}

export default Checkout
