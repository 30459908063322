import PageHeader from '../components/headers/PageHeader'
import CreateRecordAndStockItemForm from '../components/addrecord/CreateRecordForm'
import { useFetchCategoriesQuery, useFetchConditionsQuery } from '../store/recordsApi';

function AddRecord() {
    const { data: categories = [], isLoading: categoriesLoading, error: categoriesError } = useFetchCategoriesQuery();
    const { data, isLoading: conditionsLoading, error: conditionsError } = useFetchConditionsQuery();

    if (categoriesLoading || conditionsLoading) {
        return <div>Loading...</div>;
    }

    if (categoriesError || conditionsError) {
        return <div>Error loading data. Please try again later.</div>;
    }

    const conditions = (data as any).data as any[];

    return (
        <section className="p-4 w-full lg:max-w-6xl mx-auto section-container--min-height">
            <PageHeader title="Add Record" subtitle="We're excited to sell your new record!" />
            <CreateRecordAndStockItemForm genres={categories} conditions={conditions} />
        </section>
    );
}
export default AddRecord
