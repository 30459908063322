import { useState } from "react";

import Logo from "./Logo";
import ThemeButton from "./ThemeButton";
import NavLink from "./NavLink";
import ReadStockItemsFromCart from "../cart/ReadStockItemsFromCart";
import { getUserFromLocalStorage } from "../../utils/utils";

const NavBar = ({ loggedUser }: { loggedUser: boolean }) => {
  const user = getUserFromLocalStorage();
  const [isActive, setIsActive] = useState(false);
  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };
  const removeActive = () => {
    setIsActive(false);
  };

  return (
    // TODO make the navbar bigger
    <nav className={`navbar bebas-neue-regular text-3xl py-3 px-6 grid grid-cols-3 items-center w-full lg:max-w-6xl mx-auto shadow-md`}>
      <div className="flex items-center">
        <Logo />
        <ThemeButton />
      </div>
      {/* TODO maybe align better towards center */}
      <ul className={`col-span-1 flex navMenu justify-center ${isActive ? 'active' : ''}`}>
        <div className="nav-section text-center flex">
          <li onClick={removeActive}>
            <NavLink to="/crate" text="CRATE DIGGING" />
          </li>
        </div>
        <div className="flex-grow"></div>
        <div className="nav-section text-center flex">
          {loggedUser ? (
            <>
              {user?.role === "Customer" && (
              <li onClick={removeActive}>
                  <NavLink to="/cart" text="Cart" />
                  <ReadStockItemsFromCart loggedUser={loggedUser} />
                </li>
              )}
              {user?.role === "Store" && (
                <li onClick={removeActive}>
                  <NavLink to="/mystore" text="My Store" />
                </li>)}

              <li onClick={removeActive}>
                <NavLink to="/profile" text="Profile" />
              </li>
              <li onClick={removeActive}>
                <NavLink to="/logout" text="Logout" />
              </li>
            </>
          ) : (
            <>
              <li onClick={removeActive}>
                <NavLink to="/login" text="Login" />
              </li>
              <li onClick={removeActive}>
                <NavLink to="/register" text="Register" />
              </li>
            </>
          )}
        </div>
      </ul>
      <div className={`hamburger ${isActive ? 'active' : ''} col-span-2 justify-self-end`} onClick={toggleActiveClass}>
        <span className={`bar`}></span>
        <span className={`bar`}></span>
        <span className={`bar`}></span>
      </div>
    </nav>
  );
}

export default NavBar;
