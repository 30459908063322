// TODO refactor buildUrlWithQueryParams
export const buildUrlWithQueryParams = (
  baseUrl: string,
  queryParams: Record<string, any>
): string => {
  const queryString = Object.keys(queryParams)
    .filter((key) => {
      const value = queryParams[key];
      return (
        value !== undefined &&
        (!Array.isArray(value) || (Array.isArray(value) && value.length > 0))
      );
    })
    .map((key: any) => {
      const value = queryParams[key];
      if (Array.isArray(value)) {
        return value.map(
          (v: any) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`
        );
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .flat()
    .join("&");
  return queryString ? `${baseUrl}?${queryString}` : baseUrl;
};
