import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../utils/utils";

const baseUrl = apiUrl + process.env.REACT_APP_GR_PAYMENTS_PATH;

export const paymentsApi = createApi({
  reducerPath: "paymentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
  }),
  endpoints: (builder) => ({
    createPaymentIntent: builder.mutation({
      query: ({ jwtToken, userId, orderSum }) => ({
        url: `/create-intent`,
        method: "POST",
        body: { userId, orderSum },
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }),
    }),
    // getOrderList: builder.query({
    //   query: ({ jwToken, userId, orderListId }) => ({
    //     url: `/${userId}${ordersPath}/${orderListId}`,
    //     headers: {
    //       Authorization: `Bearer ${jwToken}`,
    //     },
    //   }),
    // }),
    // removeLoveTokenFromList: builder.mutation({
    //   query: ({ loveTokenId, jwToken }) => ({
    //     url: "/",
    //     method: "DELETE",
    //     body: { loveTokenId },
    //     headers: {
    //       Authorization: `Bearer ${jwToken}`,
    //     },
    //   }),
    // }),
    // updateAffectionListOrder: builder.mutation({
    //   query: ({ newOrder, jwToken }) => ({
    //     url: "/",
    //     method: "PUT",
    //     body: { newOrder },
    //     headers: {
    //       Authorization: `Bearer ${jwToken}`,
    //     },
    //   }),
    // }),
  }),
});

export const { useCreatePaymentIntentMutation } = paymentsApi;
