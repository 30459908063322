import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { apiUrl } from "../utils/utils";

const authPath = process.env.REACT_APP_GR_AUTH_PATH;
const usersPath = process.env.REACT_APP_GR_USERS_PATH;

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (userData) => ({
        url: `${authPath}/register`,
        method: "POST",
        body: userData,
      }),
    }),
    loginUser: builder.mutation({
      query: (userData) => ({
        url: `${authPath}/login`,
        method: "POST",
        body: userData,
      }),
    }),
    checkAuth: builder.query({
      query: (token) => ({
        url: `${authPath}/verify`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
      }),
    }),
    getUserInfo: builder.query({
      query: ({ token, userId }) => ({
        url: `${usersPath}/${userId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    deleteUser: builder.mutation({
      query: ({ userId, jwToken }) => ({
        url: `/user/${userId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwToken}`,
        },
      }),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useCheckAuthQuery,
  useGetUserInfoQuery,
  useDeleteUserMutation,
} = userApi;