import { useTheme } from '../../contexts/ThemeContext'

const imageUrl = process.env.REACT_APP_GR_IMAGES_BASE_URL

function RecordDisplay({ record, coverSize }: { record: any, coverSize: string }) {
    const { theme } = useTheme();
    return (
        <>
            <img className={`
            ${theme === 'light' ?
                    'background--tropical-indigo text-gray-800' :
                    'bg-indigo-900 text-gray-200'
                }`}
                src={`${imageUrl}/${record._id}-${coverSize}.avif`} alt="album cover">
            </img>
            <p className="text-2xl mt-2">
                <b>{record.title}</b><br />
                {record.artist}
            </p>
            {/* TODO genre/category stamp over the album cover, maybe with CategoryButton*/}

        </>

    )
}

export default RecordDisplay
