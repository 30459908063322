import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import useAuthenticatedUser from "../utils/useAuthenticatedUser"
import DataLoader from "../utils/DataLoader";
import { updateAuthStatus } from "../store/authSlice";
import { setUserInLocalStorage } from "../utils/utils";

function Auth() {
    const { data: response, isLoading, error } = useAuthenticatedUser();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (response) {
            setUserInLocalStorage({
                userId: response.data.id,
                role: response.data.role,
                userName: response.data.userName,
                currentOrderList: response.data.orderHistory[response.data.orderHistory.length - 1],
                ...(response.data.store && { store: response.data.store }),
            })
            setTimeout(() => {
                dispatch(updateAuthStatus())
                navigate("/profile")
            }, 10)
        }
    }, [response, navigate, dispatch]);

    return (
        <DataLoader
            isLoading={isLoading}
            data={response}
            error={error}
            render={() => {

                // TODO better auth message?
                return (
                    <>
                        <p>Almost there...</p>
                    </>
                )
            }} />
    )
}

export default Auth
