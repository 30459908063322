import PageHeader from "../components/headers/PageHeader";
import NotificationBox from "../components/shared/NotificationBox";
import { getUserFromLocalStorage } from "../utils/utils";
import DataLoader from "../utils/DataLoader";
import { useGetUserInfoQuery } from "../store/userApi";
import { useEffect, useState } from "react";
import { smallButtonStyleLight } from "../utils/utils";


const Profile = () => {
    const user = getUserFromLocalStorage()
    const { data: response, error, isLoading } = useGetUserInfoQuery({ token: user?.token, userId: user?.userId });
    const [localProfile, setLocalProfile] = useState({ firstName: '', lastName: '', userName: '' });
    const [initialProfile, setInitialProfile] = useState({ firstName: '', lastName: '', userName: '' });
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (response?.data) {
            const { firstName, lastName, userName } = response.data;
            setLocalProfile({ firstName, lastName, userName });
            setInitialProfile({ firstName, lastName, userName });
        }
    }, [response]);

    const handleEditToggle = () => {
        setEditMode(!editMode);
    };

    const handleSaveChanges = async () => {
        try {
            setInitialProfile(localProfile);
            setEditMode(false);
        } catch (error) {
            console.error("Failed to save changes", error);
        }
    };

    const handleCancelChanges = () => {
        setLocalProfile(initialProfile);
        setEditMode(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLocalProfile(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <section className="p-4 w-full lg:max-w-6xl mx-auto section-container--min-height">
            <DataLoader
                isLoading={isLoading}
                error={error}
                data={response}
                emptyMessage="You have no information yet"
                render={() => {
                    return (
                        <>
                            <PageHeader title={`Hi, ${localProfile.userName}!`} subtitle="That's your information:" />
                            <div className="flex flex-col text-center items-center mt-1 mb-10">

                                {editMode ? (
                                    <>
                                        <input
                                            className="border rounded p-1"
                                            name="firstName"
                                            value={localProfile.firstName}
                                            onChange={handleInputChange}
                                        />
                                        <input
                                            className="border rounded p-1"
                                            name="lastName"
                                            value={localProfile.lastName}
                                            onChange={handleInputChange}
                                        />
                                        <button className={smallButtonStyleLight} onClick={handleSaveChanges}>Save Changes</button>
                                        <button className={smallButtonStyleLight} onClick={handleCancelChanges}>Cancel</button>

                                    </>
                                ) : (
                                    <>
                                        <p>First Name: {localProfile.firstName}</p>
                                        <p>Last Name: {localProfile.lastName}</p>
                                        <button className={smallButtonStyleLight} onClick={handleEditToggle}>Change personal information</button>
                                    </>
                                )}
                                <br />
                                {/* TODO change password */}
                                {/* <div>
                                    <button className={smallButtonStyleLight}>Change password</button>
                                </div> */}
                                <br />
                                {/* TODO view order history */}
                                {/* <button className={smallButtonStyleLight}>View order history</button>
                                {user.orderHistory.map((order: any) => <p key={order}>{order}</p>)} */}
                            </div>
                            <div>

                        </div>
                        {/* TODO change notificationbox styles */}
                        {/* TODO check old deletion implementation */}
                        <NotificationBox message="Don't want to be part of Golden Rack anymore?" to={"/delete"} toMessage={"Delete your account"} />
                    </>
                    )
                }
                }
            />
        </section>
    );
};

export default Profile;