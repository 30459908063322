import { useDispatch, useSelector } from 'react-redux';
import { useFetchRecordsQuery } from '../../store/recordsApi';
import { PriceRangeState, RootState } from '../../types/Types';
import DataLoader from '../../utils/DataLoader';
import RecordsPreview from './RecordsPreview';
import { updateTotalPages } from '../../store/pageSlice';
import { useEffect } from 'react';

interface SectionRecordsPreviewProps {
  selectedCategories: string[];
  priceRange: PriceRangeState;
  page: number;
  sortPrice: string;
  loggedUser: boolean;
}

function SectionRecordsPreview({
  selectedCategories,
  priceRange,
  page,
  sortPrice,
  loggedUser }: SectionRecordsPreviewProps) {
  const { data: response, isLoading, error } = useFetchRecordsQuery({
    genres: selectedCategories,
    max: priceRange.max,
    min: priceRange.min,
    page,
    sortPrice
  });
  const pageState = useSelector((state: RootState) => state.page);

  const dispatch = useDispatch();

  const records = response?.data!.records;
  const totalPages = response?.data!.totalPages;

  useEffect(() => {
    if (pageState.total !== totalPages) {
      dispatch(updateTotalPages(totalPages));
    }
  }, [pageState.total, totalPages, dispatch]);


  return (
    <DataLoader
      isLoading={isLoading}
      error={error}
      data={records}
      emptyMessage="There are no records to be displayed"
      render={() => (
        <>
          <RecordsPreview records={records!} loggedUser={loggedUser} />
        </>
      )}
    />
  );
}

export default SectionRecordsPreview;
