import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSortPrice } from '../../store/sortSlice';
import { RootState, SortState } from '../../types/Types';
import { useTheme } from '../../contexts/ThemeContext';

const SortInput = () => {
    const { theme } = useTheme();
    const dispatch = useDispatch();
    const sortOrder = useSelector((state: RootState) => state.sort.price);

    const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newSortPrice = e.target.value as "asc" | "desc";
        const sortSettings: SortState = {
            price: newSortPrice
        };
        dispatch(setSortPrice(sortSettings));
    };

    return (
        <div className="flex items-center mt-4 mb-4">
            <label htmlFor="sortOrder" className="bebas-neue-regular mr-2 text-3xl text--light-cyan">Sort by price:</label>
            <select
                id="sortOrder"
                value={sortOrder}
                onChange={handleSortChange}
                // TODO style for dark theme
                className={`text-xl outline-none
                    ${theme === 'light' ?
                        'background--tropical-indigo border px-4 py-2 text--light-cyan' :
                        'text-gray-200 background--black-ce-soir border-b border-gray-600'}`
                }
            >
                <option value="asc">Low to high</option>
                <option value="desc">High to low</option>
            </select>
        </div>
    );
};

export default SortInput;
