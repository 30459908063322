import { useForm } from "react-hook-form";
import { useCreateRecordAndStockItemMutation } from "../../store/recordsApi";
import Form from "../shared/Form";
import InputField from "../shared/InputField";
import { setCreateRecordNotification, clearCreateRecordNotification } from "../../store/notificationSlice";
import HeaderTwo from "../headers/HeaderTwo";
import { getUserFromLocalStorage } from "../../utils/utils";

function CreateRecordAndStockItemForm({ genres, conditions }: { genres: any[], conditions: any[] }) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [createRecordAndStockItem, { isLoading }] = useCreateRecordAndStockItemMutation();
    const user = getUserFromLocalStorage();

    return (
        <Form
            onSubmit={handleSubmit}
            isLoading={isLoading}
            callback={createRecordAndStockItem}
            successMessage="Record created successfully! Redirecting to its page..."
            errorMessage="Error while creating record. Please try again."
            setNotification={setCreateRecordNotification}
            clearNotification={clearCreateRecordNotification}
        >
            <HeaderTwo title="Record details" />
            <InputField
                id="title"
                label="Title"
                type="text"
                register={register}
                required={true}
                errors={errors}
            />
            <InputField
                id="artist"
                label="Artist"
                type="text"
                register={register}
                required={true}
                errors={errors}
            />
            <InputField
                id="description"
                label="Description"
                type="text"
                register={register}
                required={true}
                errors={errors}
            />
            <InputField
                id="year"
                label="Year"
                type="number"
                register={register}
                required={true}
                errors={errors}
            />
            <InputField
                id="genre"
                label="Genre"
                inputType="select"
                register={register}
                required={true}
                errors={errors}
                options={genres.map((genre: any) => `${genre._id},${genre.name}`)}
            />
            <br />
            <HeaderTwo title="Stock for this record" />
            <InputField
                id="condition"
                label="Condition"
                inputType="select"
                register={register}
                required={true}
                errors={errors}
                options={conditions.map((condition: any) => `${condition._id},${condition.condition}`)}
            />
            <InputField
                id="price"
                label="Price"
                type="number"
                register={register}
                required={true}
                errors={errors}
            />
            <div className="hidden">
                <InputField
                    id="store"
                    label="Store ID"
                    type="hidden"
                    defaultValue={user?.store}
                    register={register}
                    required={true}
                    errors={errors}
                />
            </div>
        </Form>
    );
}

export default CreateRecordAndStockItemForm;